import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'

const PomnikWLadzyniuPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Pomnik pamięci ofiar II wojny światowej - Sulejówek" />
      <ReturnToPrevPage to={'/gminy/sulejowek'}>
        Materiały dotyczące gminy Sulejówek
      </ReturnToPrevPage>
      <Header1>Pomnik pamięci ofiar II wojny światowej - Sulejówek</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Skwer Pamięci w Sulejówku jest miejscem upamiętnienia ofiar egzekucji
          z 15 września 1939 roku. Była o ludność cywilna Miłosny, Zorzy i
          Cechówki. Odsłonięty w 1970 roku pomnik nosi wyryty napis “Pamięci
          mieszkańców Sulejówka poległych i zamordowanych w latach okupacji
          hitlerowskiej 1939-1944”. Tuż obok znajdują się dwie tablice z tytułem
          “Rozstrzelani przez Wehrmacht 15 IX 1939” oraz listę nazwisk ofiar.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/sulejowek/pomnik-ofiar-iiws/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: { relativePath: { regex: "/sulejowek/pomnik-ofiar-iiws/" } }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default PomnikWLadzyniuPage
